.content{
    position: absolute;
    overflow: auto;
    top: 64px;
    bottom: 0;
    right: 0;
    left: 240px;
}
.fmeaTable{
    margin:18px;
    border-collapse: collapse;
}
.fmeaTable, .fmeaTable>thead>tr>th, .fmeaTable>tbody>tr>td{
    border: 1px solid gray;
}

.fmeaTable>thead>tr>th, .fmeaTable>tbody>tr>td{
    padding: 8px;
}
.fmeaTable>tbody>tr:hover{
    background-color: #3b3b3b;
}

.input{
    margin-left:32px !important;
}

.info-button{
    margin-left:64px !important;
}
