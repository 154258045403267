.component-node{
	border: solid 2px black;
	border-radius: 5px;
	width: 130px;
	position: relative;
}

.dummy-header{
	background-color: rgba(0,0,0,0.3);
	padding: 5px;
	color: white;
	font-size: 11px;
	display: flex;
	justify-content: space-between;
}

.dummy-parallel-selector{
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	padding: 1px;
	margin:0;
	border-radius: 2px;
}

.dummy-parallel-selector:hover{
	background-color: rgba(0,0,0,0.3);
}

.port-container{
	display: flex;
	align-items: center;
	font-size: 11px;
}

.node-prop-container{
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: stretch;
}


.custom-node{
	border: solid 2px gray;
	border-radius: 5px;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
}

.dummy-ports{
	width: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
}

.custom-node-color{
	width: 100%;
	height: 100%;
	border-radius: 10px;
}

.dummy-port{
	width: 15px;
	height: 15px;
	cursor: pointer;
}

.dummy-port:hover{
	background: greenyellow;
}
